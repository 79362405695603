import React, { useEffect, useRef } from "react";
import Navbar from "../pages/Home/Navbar";
import Banner from "./Home/Banner";
import "../Assets/css/services.css";
import immigration from "../Assets/images/Eons-logo.png";
import { useState } from "react";
import CalenderIndex from "../pages/CalenderIndex";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal, Row } from "reactstrap";
import Footer from "./Footer";
import "aos/dist/aos.css";
import axios from "axios";
import Loader from "./Loader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "../Assets/css/CheckoutForm.css";
import { Config } from "../Config";
import Step5 from "./Step5";
import Step4 from "./Step4";
import Stepper from "./Stepper";
import Step3 from "./Step3";
import Step1 from "./Step1";

const info = {
  0: {
    heading: "Phone | Initial Consultation",
    description:
      "One-One session to discuss your personal options for Canada Immigration. Know your Options OR discuss your queries regarding Canadian Immigration.",
    price: "80",
    time: "30 min",
    sideboxinfo:"We'll be discussing your queries regarding Canadian Immigration in detail over an Initial Consultation.The meeting will be conducted over a voice call on WhatsApp or regular Phone Call. Time duration of this call is as per the booked time slot."
  },
  1: {
    heading: "In-Person Consultation",
    description:
      "Step into our office for expert guidance with In-Person Consultation at Eons Immigration Services.",
    price: "120 ",
    time: "30 min",
    sideboxinfo:"We'll be discussing your queries regarding Canadian Immigration in detail over an Initial Consultation. The meeting will be conducted in-person. Time duration of this call is as per the booked time slot."
  },
  2: {
    heading: "Zoom Call | Video Consultation",
    description:
      " Access professional guidance with Zoom Call | Video Consultation . Expert advice, anywhere.",
    price: "100 ",
    time: "30 min",
    sideboxinfo:"We'll be discussing your queries regarding Canadian Immigration in detail over an Initial Consultation. The meeting will be conducted over a ZoomCall or a Video Call. Time duration of this call is as per the booked time slot."
  },
};

const Services = () => {
  const stripePromise = loadStripe("pk_live_51POBkbFd7dDZ7xmQ94jfKuWkOlPNR10AyoBsnanQLb0pc69meL10fgI8NzBnYH5oAm0fwTXn0eSXFNToqQPS64nS00dftF3FTn");

  const [loading, setLoading] = useState(false);
  const [service, setService] = useState(0);
  const [bookingId, setBookingId] = useState(0);
  const [tab, setTab] = useState(0);
  const [isPaymentPopupVisible, setisPaymentPopupVisible] = useState(false);
  const [dateAndTime, setDateAndTime] = useState({ date: "", time: "",day:""});
  const [formdata, setFormdata] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    comments: "",
  });

 const [finalPrice,setFinalPrice] = useState(0);
  const [bookingData, setBookingData] = useState({
    servicelocal: 0,
    dateAndTimelocal: { date: "", time: "" },
    formdatalocal: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      comments: "",
    },
  });
  const [termsChecked, setTermsChecked] = useState(false);
  const heading = info[service].heading;
  const description = info[service].description;
  const scrollToRef = useRef(null);


  useEffect(() => {
    {tab==4&&scrollToRef.current?.scrollIntoView({ behavior: "smooth" });}
    {tab!=4&&window.scrollTo(0, 1);}
  }, [tab]);





  useEffect(() => {
    // Retrieve bookingData from local storage
    const storedData = localStorage.getItem("bookingData");
    if (localStorage.getItem("bookingId") !== null) {
      setTab(localStorage.getItem("tab"));
    }
    if (localStorage.getItem("bookingId") !== null) {
      setService(localStorage.getItem("service"));
    }
    if (localStorage.getItem("bookingId") !== null) {
      setFinalPrice(localStorage.getItem("finalprice"));
    }
    if (storedData && localStorage.getItem("bookingId") !== null) {
      // If bookingData exists, destructure it and set state
      const { servicelocal, bookingIdlocal, date, time, day,formdatalocal } =
        JSON.parse(storedData);

      setBookingId(bookingIdlocal);


      setDateAndTime({ date, time,day});
      setFormdata(formdatalocal);
    }
  }, []);

  const [errors, setErrors] = useState({});

  const handleCheckChange = (e) => {
    const isChecked = e.target.checked;
    setTermsChecked(isChecked);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: true,
    }));
  };

  const setLocalData = (tabIndex) => {
    setBookingData((prevBookingData) => {
      const updatedBookingData = {
        ...prevBookingData,
        servicelocal: heading,

        date: dateAndTime.date,
        time: dateAndTime.time,
        day:dateAndTime.day,

        formdatalocal: {
          firstname: formdata.firstname,
          lastname: formdata.lastname,
          email: formdata.email,
          mobile: formdata.mobile,
          comments: formdata.comments,
        },
      };

      localStorage.setItem("bookingData", JSON.stringify(updatedBookingData));
      return updatedBookingData;
    });
  };

  const handleTabChange = (tabIndex) => {
    if (localStorage.getItem("bookingId") != null) {
      setTab(tabIndex);
      localStorage.setItem("tab", tabIndex);
    } else {
      setTab(tabIndex);
    }
  };

  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    // Remove error message when correcting input
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: null,
    }));
  };

  const handlePreviousStep = (e) => {
    setErrors({});
    if (localStorage.getItem("bookingId") != null) {
      setTab(1);
      localStorage.setItem("tab", 1);
    } else {
      setTab(1);
    }
  };

  const handleCalendarData = (date, time,day) => {

    setDateAndTime((prev) => ({
      ...prev,
      date: date,
      time: time,
      day:day
    }));
  };
  const handlePay = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formdata, termsChecked);
    if (Object.keys(validationErrors).length === 0) {
      togglePaymentPopup();
    } else {
      setErrors(validationErrors);
      setLoading(false);
    }
  };



  const calculateFinalPrice = ()=>{
    let finalizedprice = parseInt(info[service].price);

    let pricehst = finalizedprice * (1+0.13);

    return parseFloat(pricehst).toFixed(2)


  }


  const togglePaymentPopup = () => {
    setisPaymentPopupVisible(!isPaymentPopupVisible);
    if(isPaymentPopupVisible){document.body.classList.add('modal-open');}
    else{
      document.body.classList.remove('modal-open');
    }
  };

  const handleSubmit = async (e, tab) => {
    e.preventDefault();
    let finalprice = calculateFinalPrice();
    const validationErrors = validateForm(formdata, termsChecked);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", formdata.firstname);
      formData.append("lname", formdata.lastname);
      formData.append("email", formdata.email);
      formData.append("phone", formdata.mobile);
      formData.append("description", formdata.comments);
      formData.append("slot", dateAndTime.time);
      formData.append("date", dateAndTime.date);
      formData.append("duration", info[service].time);
      formData.append("price", finalprice);
      formData.append("consultation", info[service].heading);
      await axios
        .post(
          `${Config.apiUrl}/bookAppointment`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {

          setBookingId(response.data.user_id);
          setLocalData(tab, response.data.user_id);
          setLoading(false);
          handleTabChange(tab);
          calculateFinalPrice();
          localStorage.setItem("bookingId", response.data.user_id);
          localStorage.setItem("tab", 3);
          localStorage.setItem("service",service)
          localStorage.setItem("finalprice",calculateFinalPrice());
        })
        .catch((error) => {
          console.error("Error posting form data:", error);
          // Deactivate loader
          setLoading(false);
        });

      // Deactivate loader
    } else {
      setErrors(validationErrors);
      setLoading(false);
    }
  };

  const validateForm = (formData, termsChecked) => {
    let errors = {};
    if (!formData.firstname.trim()) {
      errors.firstname = "First Name Is Required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email Address Is Invalid";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile Number Is Required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Please Enter Valid Mobile Number ";
    }
    if (!formData.lastname.trim()) {
      errors.lastname = "Last Name Is Required";
    }
    if (tab > 2 && termsChecked == false) {
      errors.terms = "please agree to Initial consultation agreement ";
    }

    return errors;
  };
  return (
    <div className="">

      {loading && <Loader />}
      <Navbar />

      <Banner />
      <div className="bd-color-grey">
        <div className="container-3 pt-0">
          <div className="pt-40 pb-50">
            <div className="bd-multi-form-card">
              {/* stepper code */}
              <Stepper tab={tab}/>

              {/* services cards */}
              {tab == 0 && (
                <Step1 handleTabChange={handleTabChange} setService={setService} />
              )}
              {/* calender */}
              {tab == 1 && (
                <div>
                  <div>
                    <h1 className="service-heading">Select Date And Time </h1>
                    <div class="dotted-box" >
                      <span class="dotted"></span>
                      <span class="dotted"></span>
                      <span class="dotted"></span>
                    </div>
                  </div>
                  <CalenderIndex
                    tab={tab}
                    handleTabChange={handleTabChange}
                    onDataReceived={handleCalendarData}
                    service={service}
                  />
                </div>
              )}
              {/* personal information */}
              {tab == 2 && (
                <Step3 immigration={immigration}  dateAndTime={dateAndTime} info={info} service={service} formdata={formdata} handleChange={handleChange} errors={errors} handleSubmit={handleSubmit} handlePreviousStep={handlePreviousStep} description={description}/>
              )}
              {/* booking summary */}
              {tab == 3 && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                 <Step4 formdata={formdata} heading={heading} description={description} info={info} service={service} dateAndTime={dateAndTime} termsChecked={termsChecked} handleCheckChange={handleCheckChange} handlePay={handlePay} setTab={setTab} errors={errors}  setDateAndTime={setDateAndTime} setFormdata={setFormdata} setTermsChecked={setTermsChecked}/>
                </div>
              )}
              {/* booking receipt */}
              {tab == 4 && (
                <div>
                  <div className="d-flex flex-column  justify-content-center align-items-center mb-4">
                    <Step5 scrollToRef={scrollToRef} formdata={formdata} dateAndTime={dateAndTime}  info={info} service={service} heading={heading} setDateAndTime={setDateAndTime} setFormdata={setFormdata} setTermsChecked={setTermsChecked} setTab={setTab}/>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        isOpen={isPaymentPopupVisible}
        toggle={togglePaymentPopup}
        // size="sm"
        backdrop="static"
        centered
        className="modal-content2 z-3 "
      >
        <div className="popup-container">
          <div
            id="paymentPopup"
            className={`paymentPopup ${isPaymentPopupVisible ? "" : "hide"}`}
          >
            <div className="paymentpopup-header">Stripe Payment:
            <i class="bi bi-x" onClick={()=>togglePaymentPopup()}></i>
            </div>
            <div className="paymentpopup-body">
              <Elements stripe={stripePromise}>
                <CheckoutForm price={localStorage.getItem("finalprice")} setTab={setTab} togglePaymentPopup={togglePaymentPopup} />
              </Elements>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Services;
