import React from 'react'

const Stepper = ({tab}) => {
  return (
   
      <div class="stepper">
                <div
                  class={`step ${
                    tab === 0 ? "active" : tab > 0 ? "completed" : ""
                  }`}
                >
                  <div class="step-label"></div>
                  <div class="step-description">Select The Service</div>
                </div>
                <div
                  class={`step ${
                    tab === 1 ? "active" : tab > 1 ? "completed" : ""
                  }`}
                >
                  <div class="step-label"></div>
                  <div class="step-description">Select Date And Time</div>
                </div>
                <div
                  class={`step ${
                    tab === 2 ? "active" : tab > 2 ? "completed" : ""
                  }`}
                >
                  <div class="step-label"></div>
                  <div class="step-description">Personal Information</div>
                </div>
                <div
                  class={`step ${
                    tab === 3 ? "active" : tab > 3 ? "completed" : ""
                  }`}
                >
                  <div class="step-label"></div>
                  <div class="step-description">Booking Summary</div>
                </div>
                <div
                  class={`step ${
                    tab === 4 ? "active" : tab >= 4 ? "completed" : ""
                  }`}
                >
                  <div class="step-label"></div>
                  <div class="step-description">Booking Receipt</div>
                </div>
              </div>
   
  )
}

export default Stepper
