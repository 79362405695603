import React, { useState, useEffect } from "react";
import Logo from "../../Assets/images/Eons-logo.png";
import CricLogo from "../../Assets/images/cric-logo.jpg";
import accpLogo from "../../Assets/images/accp.jpeg";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const openNavbar = () => {
    document.getElementById("mySidenav").style.width = "300px";
    
  };

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  const [scroll, setScroll] = useState(false);



  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 180);
    });
  }, []);

  return (
    <div>
      <div className="bd-top-nav bd-reposive-collapse">
        <div className="container-3 ">
          <div className="row">
            {/* <div className="display-flex col-md-10 "> */}
            <div className="bd-email-size ">
              <div>
                <i className="fa fa-envelope-open pr-10"></i>
                <span>
                  <a
                    href="mailto:info@eonsimmigration.ca"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    info@eonsimmigration.ca
                  </a>
                </span>
              </div>
            </div>
            <div className="bd-phone-size ml-10">
              <div>
                <i className="fa fa-phone-volume pr-10 "></i>
                <span style={{display:"inline-block"}} className="pe-2">
                  <a
                    href="tel:+1 647 450 9297"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    +1 647 450 9297 /   
                  </a>
                </span>
                <span style={{display:"inline-block"}} className="">
                  <a
                    href="tel:+1 647 425 9297"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                     +1 647 425 9297
                  </a>
                </span>
               
              </div>
            </div>
            <div className="col-md-2 ml-10">
              <div className="bd-color-white">
                <i class="fa-brands fa-whatsapp pr-5"></i>
                <span>
                  <a
                    href="tel: +1 647 425 9297"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    {" "}
                    +1 647 425 9297
                  </a>
                </span>
              </div>
            </div>
            {/* </div> */}
            <div className="bd-social-icons pr-0">
              <div className="display-flex justify-content-right">
                
                <div className="mr-35  text-right">
                          <NavLink style={{textDecoration:"none"}} to="#">
                            <a
                              className="btn-navbar"
                              
                            >
                              
                              Book a Consultation{" "}
                              <i
                                className="fa fa-arrow-right-long"
                                style={{ transform: "translateY(2px)" }}
                              ></i>
                            </a></NavLink>
                          </div>
             
                <div className="mr-25">
                  <div>
                    <a
                      href="https://www.facebook.com/EONS.IMMIGRATION/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f text-white"></i>
                    </a>
                  </div>
                </div>
                <div className="mr-25">
                  <div>
                    <a
                      href="https://www.instagram.com/eons_immigration/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram text-white"></i>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a
                      href="https://www.google.com/maps/place/EONS+Immigration+%7C+Canada+Migration/@62.6573279,-95.989235,3z/data=!3m1!4b1!4m6!3m5!1s0x882c0b9411d49d0b:0x4e4b2120a4d6bbda!8m2!3d62.6573279!4d-95.989235!16s%2Fg%2F11stklth58?entry=ttu"
                      target="_blank"
                    >
                      <i className="fa-brands fa-google text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        className={
          scroll
            ? "navbar navbar-expand-lg navbar-light bg-white "
            : `navbar navbar-expand-lg navbar-light bg-white `
        }
      >
        <div className="containers w-100">
          <div className="container-fluid display-flex align-item-center mobile-w-100 mobile-justify-between">
            <div className="ml mr-40 bd-no-spacing-resp">
              <a
                className="navbar-brand margin-left-minus"
                href="https://www.eonsimmigration.ca/"
                target="_blank"
              >
                <img src={Logo} className="bd-web-view" />
              </a>
            </div>

            <div className="bd-mobile-view fa-bars-mobile">
              <a onClick={openNavbar}>
                <i className="fa fa-bars"></i>
              </a>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-item-center">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/study-visa/"
                  >
                    Study Abroad
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/visitor-visa/"
                  >
                    Visitor Visa
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/sponsorship/"
                  >
                    Sponsorship
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/lmia/"
                  >
                    
                    Lmia
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/express-entry/"
                  >
                    Express Entry
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.eonsimmigration.ca/other-programs/"
                  >
                    Other Programs
                  </a>
                </li>
                <li className="nav-item pl-10">
                  <a className="nav-link " href="#">
                    <img src={CricLogo} className=" pulse" height="50px" />
                  </a>
                </li>
                <li className="nav-item pl-10">
                  <a className="nav-link " href="#">
                    <img src={accpLogo} className=" pulse" height="40px" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div id="mySidenav" class="sidenav">
        <div className="display-flex justify-space-between">
          <img src={Logo} className="mt-30" />
          <a className="closebtn" onClick={closeNav}>
            &times;
          </a>
        </div>
        <div className="mt-20 ">
          <div>
            <a
              className="btn-nav-link"
              target="_blank"
              href="https://www.eonsimmigration.ca/"
            >
              {" "}
              Home
            </a>
          </div>
          <div>
            <a
              className="btn-nav-link"
              target="_blank"
              href="https://www.eonsimmigration.ca/study-visa/"
            >
              {" "}
              Study Abroad
            </a>
          </div>
          <div>
            <a
              className="btn-nav-link"
              target="_blank"
              href="https://www.eonsimmigration.ca/visitor-visa/"
            >
              {" "}
              Visitor Visa
            </a>
          </div>
          <div>
            <a
              className="btn-nav-link"
              target="_blank"
              href="https://www.eonsimmigration.ca/sponsorship/"
            >
              {" "}
              Sponsorship
            </a>
          </div>
          <div>
            <a
              className="btn-nav-link"
              target="_blank"
              href="https://www.eonsimmigration.ca/lmia/"
            >
              {" "}
              Lmia
            </a>
          </div>
          <div>
            <a
              className="btn-nav-link"
              target="_blank"
              href="https://www.eonsimmigration.ca/express-entry/"
            >
              {" "}
              Express Entry
            </a>
          </div>
          <div>
            <a
              className="btn-nav-link "
              target="_blank"
              href="https://www.eonsimmigration.ca/other-programs/"
            >
              {" "}
              Other Programs
            </a>
          </div>

          <div className="pt-20 pl-25 text-white">
            <i className="fa fa-envelope-open pr-10 color-red "></i>
            <span>
              <a
                href="mailto:info@eonsimmigration.ca"
                style={{ color: "white", textDecoration: "none" }}
              >
                info@eonsimmigration.ca
              </a>
            </span>
          </div>
          <div className="pt-20 pl-25 text-white">
            <i className="fa fa-phone-volume pr-10 color-red"></i>
            <span>
              <a
                href="tel:+1 647 450 9297"
                style={{ color: "white", textDecoration: "none" }}
              >
                +1 647 425 9297
              </a>
            </span>
          </div>
          <div className="pt-20 pl-25 text-white">
            <h3>Office Address</h3>
            <p>118 Julia Crescent, Kitchener, Ontario, Canada - N2E 3M7</p>
          </div>

          <div className="d-flex pt-20 pl-25">
           
            <div className="pr-15">
              <a
                href="https://www.facebook.com/EONS.IMMIGRATION/"
                target="_blank"
              >
                <i className="fa-brands fa-facebook-f text-white"></i>
              </a>
            </div>
            
            <div className="pr-15">
              <a
                href="https://www.google.com/maps/place/EONS+Immigration+%7C+Canada+Migration/@62.6573279,-95.989235,3z/data=!3m1!4b1!4m6!3m5!1s0x882c0b9411d49d0b:0x4e4b2120a4d6bbda!8m2!3d62.6573279!4d-95.989235!16s%2Fg%2F11stklth58?entry=ttu"
                target="_blank"
              >
                <i className="fa-brands fa-google text-white"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
