import React from "react";
import "../Assets/css/Loader.css"

const Loader = () => {
  return (
    <div className="spinner-loader">
      <div>
        <div class="reverse-spinner"></div>
      </div>

      <div>
        <div class="hm-spinner"></div>
      </div>
    </div>
  );
};

export default Loader;
