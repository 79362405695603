import React from "react";
import "../Assets/css/footer.css";


const Footer = () => {
  return (
    <footer className="main-footer about-page bg-color-2">
      <div className="auto-container">
        <div className="footer-top">
          <div className="widget-section">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget logo-widget">
                  <figure className="footer-logo">
                    <a href="https://www.eonsimmigration.ca">
                      <img
                        src="https://www.ztpl.net/eons/wp-content/uploads/2022/12/EONS-FINAL-LOGO-200x90-pxl.png"
                        style={{ maxWidth: "213px" }}
                      ></img>
                    </a>
                  </figure>
                  
                  <div className="tsting">
                    <img
                      className="test-rcic"
                      src="https://www.eonsimmigration.ca/wp-content/themes/eonimmigration/assets/images/cric-logo.jpg"
                    ></img>
                  </div>
                 
                  
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div class="footer-widget links-widget">
                  <div class="widget-title">
                    <h3>Immigration</h3>
                  </div>
                  <div class="widget-content">
                    <div class="menu-immigration-container">
                      <ul id="menu-immigration" class="list clearfix">
                        <li
                          id="menu-item-1183"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1183"
                        >
                          <a href="https://www.eonsimmigration.ca/other-programs/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Other Programs
                          </a>
                        </li>
                        <li
                          id="menu-item-15250"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15250"
                        >
                          <a href="https://www.eonsimmigration.ca/study-visa/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Study Abroad
                          </a>
                        </li>
                        <li
                          id="menu-item-15251"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15251"
                        >
                          <a href="https://www.eonsimmigration.ca/visitor-visa/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Visitor Visa
                          </a>
                        </li>
                        <li
                          id="menu-item-1186"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1186"
                        >
                          <a href="https://www.eonsimmigration.ca/check-your-eligibility/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Check Your Eligibility
                          </a>
                        </li>
                        <li
                          id="menu-item-1187"
                          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1187"
                        >
                          <a href="https://crm.eonsimmigration.ca/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Client Portal
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div class="footer-widget links-widget">
                  <div class="widget-title">
                    <h3>Quick Links</h3>
                  </div>
                  <div class="widget-content">
                    <div class="menu-quick-links-container">
                      <ul id="menu-quick-links" class="list clearfix">
                        <li
                          id="menu-item-286"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-286"
                        >
                          <a href="https://www.eonsimmigration.ca/about-us/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            About Us
                          </a>
                        </li>
                        <li
                          id="menu-item-1188"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1188"
                        >
                          <a href="https://www.eonsimmigration.ca/sponsorship/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Sponsorship
                          </a>
                        </li>
                        <li
                          id="menu-item-1189"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1189"
                        >
                          <a href="https://www.eonsimmigration.ca/faq/">
                          <i className="bi bi-arrow-right arrow-icon"></i>FAQ</a>
                        </li>
                        <li
                          id="menu-item-1190"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1190"
                        >
                          <a href="https://www.eonsimmigration.ca/contact-us/">
                          <i className="bi bi-arrow-right arrow-icon"></i>
                            Contact Us
                          </a>
                        </li>
                        <li
                          id="menu-item-1191"
                          class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-1191"
                        >
                          <a
                            href="https://www.eonsimmigration.ca/"
                            aria-current="page"
                          >
                            <i className="bi bi-arrow-right arrow-icon"></i>
                            Immigration News
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget newsletter-widget">
                  <div className="widget-title">
                    <h3>Contact Us</h3>
                  </div>
                  <div class="widget-content">
                    <h6 style={{ color: "#7b8a9a" }}>Phone</h6>
                    <p>
                    <a href="tel:+16474259297">+1 647 425 9297</a>

                      <br/>
                      <a href="tel:+16474509297">+1 647 450 9297</a>
                    </p>
                    <h6 style={{ color: "#7b8a9a" }}>Send Message</h6>
                    <p>
                      <a href="mailto:operations@eonsimmigration.ca">operations@eonsimmigration.ca</a>
                    </p>
                    <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/EONS.IMMIGRATION/"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li><a href="https://g.page/r/Cdq71qQgIUtOEB0"><i class="fab fa-google"></i></a></li> 
                  </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom clearfix">
          <div class="copyright pull-left">
            <p class="text-white">
              Eons Immigration (©) 2022 All rights reserved
            </p>
          </div>
          <div class="menu-footer-menu-container">
            <ul id="menu-footer-menu" class="footer-nav pull-right clearfix">
              <li
                id="menu-item-283"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-283"
              >
                <a
                  rel="privacy-policy"
                  href="https://www.eonsimmigration.ca/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </li>
              <li
                id="menu-item-284"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-284"
              >
                <a href="https://www.eonsimmigration.ca/sitemap/">Sitemap</a>
              </li>
              <li
                id="menu-item-285"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-285"
              >
                <a href="https://www.eonsimmigration.ca/terms-conditions/">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
