
import { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import moment from "moment";

const useCalendar = (selectedDate, service) => {
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  // const calendarID = `dummy05zithas@gmail.com` //ztpl;
    const calendarID = `operations@eonsimmigration.ca`;
  // const apiKey = `AIzaSyCiXDH_F7EVUMFOi-FhPMb583A0kzPQibE` //ztpl;
  const apiKey = `AIzaSyC3BK3aqHdYsNwpcjtNiIRe7r6KBme9Qbk`;

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        await gapi.client.init({
          apiKey: apiKey,
        });
        const timeMin = new Date(selectedDate).toISOString();
        const endDate = new Date(selectedDate);
        endDate.setUTCHours(23, 59, 59, 999); // Set time to the end of the day
        const timeMax = endDate.toISOString();
        const response = await gapi.client.request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          params: {
            timeMin: timeMin,
            timeMax: timeMax,

          },
        });
        const events = response.result.items || [];
        const slots = calculateAvailableTimeSlots(events, selectedDate);
        setAvailableSlots(slots);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };

    gapi.load("client", fetchEvents);
  }, [selectedDate]);

  const calculateAvailableTimeSlots = (events) => {
    const availableSlots = [];
    let startTime = moment(selectedDate).toDate();

    startTime.setHours(11, 30, 0, 0);
    let endTime = moment(selectedDate).toDate();
    let day = endTime.getDay();


    if (day === 6) {
      endTime.setHours(17, 30, 0, 0);
    } else {
      service == 0 || service == 2
        ? endTime.setHours(20, 30, 0, 0)
        : endTime.setHours(18, 30, 0, 0);
    }
    let slotId = 0;

    const isDayUnavailable = events.some((event) => {
      const date = new Date(selectedDate);
      const eventStart = moment(event.start.date).toDate(); // Use event start date
      const eventEnd = new Date(event.end.date); // Use event end date
      return date >= eventStart && date <= eventEnd;
    });


    if (isDayUnavailable) {
      return availableSlots; // Whole day is unavailable, return empty slots
    }

    while (startTime < endTime) {
      // Check if the current time slot is after the current time
      const canadaTime = new Date().toLocaleString('en-US', { timeZone: 'America/Toronto' })
      const currentTime = new Date(canadaTime);
      const halfHourAfterCurrentTime = new Date(
        currentTime.getTime() + 30 * 60 * 1000
      );
      const slotStart = new Date(startTime);
      if (slotStart > halfHourAfterCurrentTime) {

        const isBooked = events.some((event) => {
          const eventStart = new Date(new Date(event.start.dateTime).toLocaleString('en-US', { timeZone: 'America/Toronto' }));
          const eventEnd = new Date(new Date(event.end.dateTime).toLocaleString('en-US', { timeZone: 'America/Toronto' }));
          const slotEnd = new Date(startTime.getTime() + 30 * 60 * 1000);
          const isOverlap = eventStart < slotEnd && eventEnd > slotStart;
          return (
            isOverlap ||
            (eventStart.getTime() === slotStart.getTime() &&
              eventEnd.getTime() === slotEnd.getTime())
          );
        });

        if (!isBooked) {

          const endTimeSlot = new Date(startTime.getTime() + 30 * 60 * 1000);
          const slot = {
            id: slotId++,
            from: startTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            to: endTimeSlot.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
          };

          availableSlots.push(slot);
        }
      }

      // Increment startTime by 30 minutes
      startTime = new Date(startTime.getTime() + 30 * 60 * 1000);
    }

    return availableSlots;
  };

  return { loading, availableSlots };
};

export default useCalendar;