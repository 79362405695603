import React from 'react'
import { NavLink } from "react-router-dom";
import { Config } from "../Config";
import stripe from "../Assets/images/stripe.png";

const Step4 = ({ formdata, description, info, service, dateAndTime, termsChecked, handleCheckChange, handlePay, setTab, errors, setDateAndTime, setFormdata, setTermsChecked }) => {
  const heading = info[service].heading;
  const price = localStorage.getItem("finalprice");

  return (
    <div>
      <div class="bg">
        <div class="card-confirmation">
          <span class="card__success">
            <i class="bi bi-check"></i>
          </span>

          <h1 class="card__msg">Booking Summary</h1>
          <h2 class="card__submsg">
            Your Booking Details are below:
          </h2>

          <div class="card__body">
            <div class="card__recipient-info">
              <div className="d-flex">
                <p class="card__recipient">Customer Name:</p>
                <p>
                  {formdata.firstname + " " + formdata.lastname}
                </p>
              </div>
              <div className="d-flex">
                <p class="card__recipient">Service Type:</p>
                <p>{heading}</p>
              </div>
              <div>
                <p style={{ textAlign: "start", fontSize: "17px" }}>
                  {info[service].description}
                </p>
              </div>
              <div className="d-flex">
                <p class="card__recipient">Email:</p>
                <p>{formdata.email}</p>
              </div>
              <div className="d-flex">
                <p class="card__recipient">Order Amount:</p>
                <p>{`$` + info[service].price + ` CAD` + ` + ` + `13% HST`}</p>
              </div>

              <div className="d-flex">
                <p class="card__recipient">Final Amount:</p>
                <p>{`$` + price + ` CAD`}</p>
              </div>
              <div className="d-flex">
                <p class="card__recipient">Date: </p>
                <p> {`${dateAndTime.date}, ${dateAndTime.day}`}</p>
              </div>
              <div className="d-flex ">
                <p class="card__recipient"> Time:</p>
                <p>
                  {" "}
                  {localStorage.getItem("bookingId") != null
                    ? dateAndTime.time
                    : dateAndTime.time}
                </p>
              </div>
              <div className="conditions-div">
                <input
                  type="checkbox"
                  value={termsChecked}
                  name="terms"
                  onClick={handleCheckChange}
                  style={{ width: "14px", height: "14px" }}
                ></input>
                <p >
                  I agree to the{" "}
                  <NavLink to={`${Config.appUrl}/terms`} target="_blank" className="fw-bold">
                    initial consultation agreement{" "}
                  </NavLink>

                </p>
              </div>
              {errors.terms && (
                <div
                  className="text-danger error-msg"
                  style={{ textAlign: "start", marginBottom: "10px", marginTop: "-3px", marginLeft: "5px" }}
                >
                  {errors.terms}
                </div>
              )}
            </div>

            <p class="card__method">Payment Method:</p>
            <div class="card__payment text-start">
              <div class="card__card-details ">
                <input
                  type="radio"
                  checked
                  className="radio-input"
                  style={{ display: "none" }}
                ></input>
                {/* <div class="custom-radio">
                            <input type="radio" id="radio1" name="customRadio" value="option1"/>
                            <label for="radio3" onClick={handleRadioChange}></label>
                            </div> */}

                <div>
                  {" "}
                  <ul className="modal-ul">
                    <li className="modal-li">
                      <p className="radiobox">
                        <img src={stripe}></img>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start button-box">
              {/* <button
                            className="button"
                            onClick={(e) => {
                              if (localStorage.getItem("bookingId") != null) {
                                setTab(2);
                                localStorage.setItem("tab", 2);
                              } else {
                                setTab(2);
                              }
                            }}
                          >
                            <i className="bi bi-arrow-left me-1"></i>
                            Previous Step
                          </button> */}

              <button
                className="button-pay"
                onClick={(e) => {
                  handlePay(e);
                }}
              >
                Pay Now
                <i className="bi bi-arrow-right ms-1"></i>
              </button>
            </div>
            <p style={{ textAlign: "left", fontSize:"14px", fontWeight:"600" }} className='ms-3'>If you wish to start booking process again
              <a className='ms-1 '
              style={{cursor:"pointer", color:"#bf2323"}}
                onClick={() => {
                  localStorage.removeItem("bookingData");
                  localStorage.removeItem("bookingId");
                  localStorage.removeItem("tab");
                  setDateAndTime({ date: "", time: "", day: "" });
                  setFormdata({
                    firstname: "",
                    lastname: "",
                    email: "",
                    mobile: "",
                    comments: "",
                  })
                  setTermsChecked(false);
                  setTab(0);
                }}>click here</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step4
