import React from "react";

const Banner = () => {
  return (
    <div>
      <div className="bg-grey">
        <div className="container-3 bd-banner-pad">
          <div>
            <div className="mobile-padding">
              <h1 className="banner-main-head mb-20">Book A Consultation</h1>
              <div class="dotted-box">
                <span class="dotted"></span>
                <span class="dotted"></span>
                <span class="dotted"></span>
              </div>
              <div>
                <div className="display-flex bd-white-color banner-subtitle">
                  <div className="pr-20">
                    <div>
                      <i className="fa fa-house pr-10"></i>
                      Home
                    </div>
                  </div>
                  <div>
                    <div>
                      <i className="fa fa-arrow-right-long pr-15"></i>
                     Book A Consultation
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
