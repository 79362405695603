import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import moment from "moment";
import swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import useCalendar from "./Calendar";
import Loader from "./Loader";

const CalenderIndex = ({ tab, handleTabChange, onDataReceived, service }) => {
  const [prevDate, setPrevDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isWeekendDate, setIsWeekendDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [modalTerms, setModalTerms] = useState(false);
  const [isPopupVisible2, setPopupVisible2] = useState(false);
  const [timePopUp, setTimePopUp] = useState(true);
  const [time, setTime] = useState("");

  const sendDataToParent = () => {
    const date = moment(selectedDate).format("DD-MM-YYYY");
    const timetoParent = time;
    const day = moment(selectedDate).format("dddd");
    onDataReceived(date, timetoParent, day);
  };
  const togglePopup2 = () => {
    setPopupVisible2(!isPopupVisible2);
  };
  const [modalTermsContent, setModalTermsContent] = useState({
    title: "Initial Consultation Agreement",
    text: [
      {
        termtitle: "Scope of Service",
        termtext: [
          "Review information provided by the Client and provide consultation on the current status of Canadian immigration laws and policies where it relates to Client’s inquiry;",
          "The work done under this agreement will be limited to the time as mentioned in the selected option used while booking the Initial Consultation;",
          "The information provided to the consultant in the Initial Consultation Form and during the consultation is for the purpose of obtaining a consultation only and is not intended for the purpose of retaining the RCIC for application, case, submission or representation in any matter, until a written legal services retainer agreement establishing such nature of the relationship has been executed between the parties.",
        ],
      },
      {
        termtitle: "Limits of Engagement",
        termtext: [
          "The services to be provided under this Agreement are limited to a single Initial consultation only and on the relevant matters described above. This agreement does not obligate the RCIC to not provide further information or advice to the Client or to act for the Client in any application, representation, submissions, or proceedings. Should the Client require further services, advice, or representation from the RCIC following this initial consultation, the Client must sign a separate retainer agreement with the RCIC.",
        ],
      },
      {
        termtitle: "Client Responsibility",
        termtext: [
          "The Client must provide the RCIC with such factual information and documentation as are required to perform the consultation.",
          "The Client must be accurate and honest and must inform the RCIC of all information, even if negative or adverse, which might be relevant to the advice provided by the RCIC in this matter. Failure to fully disclose all relevant information to the RCIC shall render the consultation provided null and void and shall void this Agreement, or seriously affect the outcome of the application of the Client or the retention of any status that the Client may obtain. In such an event, the RCIC shall not be held responsible or liable for any of the Client’s matters. The fee paid by the Client shall be non-refundable.",
          "The Client fully acknowledges that all information given during this consultation is provided as per the Canadian Immigration laws and policies valid until the time of consultation delivery. All information given during this consultation shall be considered null and void if there is a change in policy or immigration law at a later date after the advice is provided to the Client. The fee paid by the Client shall be non-refundable.",
        ],
      },
      {
        termtitle: "Termination",
        termtext: [
          "a)This Agreement is considered terminated upon completion of tasks identified under Clause 3 of this agreement. It is the Clients’ responsibility to settle any outstanding fees or dues for work performed by RCIC prior to termination.",
          "b)    Upon official termination, if the CLIENT(s) decides to proceed with any future applications that are personally done by the Client(s) as self-application and/or their representatives, lawyers or consultants will be done without the knowledge, approval, or involvement of RCIC and should these cause any impact, refusal, rejection, negative results or future inadmissibility, Eons Immigration and appointed RCIC shall not be held liable or be held legally responsible for any consequences.",
        ],
      },
      {
        termtitle: "Payment terms and Refund Policy",
        termtext: [
          "a)Fees shall be deemed earned by the RCIC immediately upon the commencement of the provision of professional advice and consultation.",
          "b)    Fees paid by the Client under this agreement are non-refundable.",
        ],
      },
      {
        termtitle: "Consent to terms and conditions",
        termtext: [
          "If you choose to proceed with the booking, it is deemed that you agree to the Initial Consultation Agreement",
        ],
      },
    ],
  });
  const toggleModalTerms = () => {
    setModalTerms(!modalTerms);
  };
  const handleSlotClick = (slot) => {
    const slotStartTime = moment(slot.from, "hh:mm A");
    if (
      moment(selectedDate, "DD-MM-YYYY").isSame(moment(), "day") &&
      slotStartTime.isBefore(moment())
    ) {
      return;
    }

    // Set the selected slot and time
    setSelectedSlot(slot.id);
    setTime(`${slot.from}-${slot.to}`);
  };

  const isWeekend = (date) => {
    const dayOfWeek = moment(date).day();

    return dayOfWeek === 0;
  };

  let { loading } = useCalendar(selectedDate);

  let { availableSlots } = useCalendar(selectedDate, service);
  const todaySlots = availableSlots;

  const handleDateChange = async (newDate) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const sDate = moment(newDate.$d).format("YYYY-MM-DD");
    if (sDate < currentDate) {
      togglePopup2();
      setPrevDate(true);
    } else if (sDate >= currentDate) {
      setSelectedDate(moment(newDate.$d).format("YYYY-MM-DD"));
      setIsWeekendDate(newDate.$d);
      setPrevDate(false);
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "instant",
    });
  }, []);
  const loadDate = () => {
    const currentDate = new Date();
    isWeekend(currentDate);
    handleDateChange(currentDate);
  };

  useEffect(() => {
    loadDate();
  }, []);

  const handleSubmitAppoinment = async (e) => {
    e.preventDefault();
    if (selectedSlot !== null && selectedSlot !== "") {
      setModalTerms(true);
    } else if (selectedSlot == null || selectedSlot == "") {
      swal({
        text: "Please select a time slot",
        icon: "warning",
      });
    }
  };

  // ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,

  return (
    <div>
      {loading && <Loader />}
      {/* <Nav /> */}

      <div className="container-main pt-0 bg-main">
        <form onSubmit={handleSubmitAppoinment}>
          <div className="row pt-4 justify-content-between">
            <div className="col-md-5 ps-0 ">

              <DatePicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                value={selectedDate}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div
                className={`height-100 ps-0  ${
                  prevDate || isWeekend(selectedDate)
                    ? "d-flex align-items-center"
                    : ""
                }`}
              >
                <div
                  className={`bg-white  height-100  shadow-sm  p-4 ${
                    prevDate || isWeekend(selectedDate)
                      ? ""
                      : " shadow-sm calendar-inner-container"
                  }`}
                  style={{ minWidth: "300px" }}
                >
                  {selectedDate === moment().format("YYYY-MM-DD") ? (
                    todaySlots.length !== 0 ? (
                      <h2 className="text-mbd-center fs-mbd-3">
                        Select a Time Slot
                      </h2>
                    ) : (
                      ""
                    )
                  ) : (
                    !isWeekend(selectedDate) &&
                    !prevDate &&
                    todaySlots.length !== 0 && (
                      <h2 className="text-mbd-center fs-mbd-3">
                        Select a Time Slot
                      </h2>
                    )
                  )}
                  {todaySlots.length !== 0 &&
                    !isWeekend(isWeekendDate) &&
                    !prevDate && (
                      <div className="row ">
                        {selectedDate === moment().format("YYYY-MM-DD")
                          ? todaySlots.map((slot, index) => {
                              return (
                                <div
                                  key={slot.id}
                                  className={`${
                                    todaySlots.length === 1
                                      ? "col-md-12"
                                      : "col-md-6"
                                  } mb-2 mt-2`}
                                >
                                  <div
                                    className={`time-slot border text-center ${
                                      selectedSlot === slot.id
                                        ? "selected-timeSlot"
                                        : ""
                                    }`}
                                    onClick={() => handleSlotClick(slot)}
                                  >
                                    {`${slot.from} - ${slot.to}`}
                                  </div>
                                </div>
                              );
                            })
                          : todaySlots.map((slot, index) => (
                              <div key={slot.id} className="col-md-6 mb-2">
                                <div
                                  className={`time-slot border text-center ${
                                    selectedSlot === slot.id
                                      ? "selected-timeSlot"
                                      : ""
                                  }`}
                                  onClick={() => handleSlotClick(slot)}
                                >
                                  {`${slot.from} - ${slot.to}`}
                                </div>
                              </div>
                            ))}
                        {}
                      </div>
                    )}

                  {todaySlots.length === 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                          <h4>There are No Slots Available.</h4>
                        </div>
                      </div>
                    </div>
                  )}

                  {isWeekend(selectedDate) && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                          <h4>Please Select a Non Weekend Day</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-between justify-content-mbd-center align-items-center py-0">
            <div className=""></div>
            <div className="pt-mbd-4">
              <button
                className="button"
                onClick={() => {
                  handleTabChange(0);
                }}
              >
                <i className="bi bi-arrow-left me-1"></i>
                Previous Step
              </button>

              {!prevDate && !isWeekend(isWeekendDate) && time && (
                <button
                  type="submit"
                  className="button-book"
                  style={{ cursor: "pointer" }}
                >
                  Book Appointment
                  <i
                    className="fa fa-arrow-right-long"
                    style={{ transform: "translateY(2px)", marginLeft: "4px" }}
                  ></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {/* <Footer /> */}

      <Modal
        isOpen={modalTerms}
        toggle={toggleModalTerms}
        // size="sm"
        centered
        className="createTerms z-3 "
      >
        <ModalBody className="modal-body border-0 p-0">
          <div className="container py-5 " style={{ paddingRight: "1.5rem" }}>
            <div className="row d-xxl-flex d-justify-content-center d-align-items-center">
              <div className="col-lg-12 px-0">
                <div>
                  <h5 className="modal-title">{modalTermsContent.title}</h5>
                  <div className="line"></div>
                  <div className="terms-div">
                    <ul className="modal-ul">
                      {modalTermsContent.text.map((term, index) => (
                        <li key={index} className="modal-li">
                          <strong className="modal-text">
                            {term.termtitle}:{" "}
                          </strong>
                          {term.termtext.map((termtext) => {
                            return <p>{termtext}</p>;
                          })}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="me-4 pt-2" style={{ textAlign: "end" }}>
                    <div className="line mt-2"></div>

                    <button
                      className="button-book"
                      type="button"
                      onClick={() => {
                        handleTabChange(2);
                        sendDataToParent();
                      }}
                    >
                      Accept & Continue
                      <i
                        className="fa fa-arrow-right-long"
                        style={{
                          transform: "translateY(2px)",
                          marginLeft: "4px",
                        }}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isPopupVisible2}
        toggle={togglePopup2}
        // size="sm"
        centered
        className="modal-content2 z-3 "
      >
        <div className="popup-container">
          <div
            id="myPopup"
            className={`popup popup-terms${isPopupVisible2 ? "" : "hide"}`}
          >
            <div className="popup-header">Warning!</div>
            <div className="popup-body">
              Kindly Select a Date From Today Onward for Further Proceedings.
            </div>
            <div className="popup-footer">
              <button className="toggle button" onClick={togglePopup2}>
                Got it!
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={timePopUp}
        // toggle={togglePopup2}
        // size="sm"
        centered
        className="modal-content2 z-3 "
      >
        <div className="popup-container">
          <div id="myPopup" className={`popup popup-terms ${timePopUp ? "" : "hide"}`}>
            <div className="popup-header text-left">Notification</div>
            <div className="popup-body">
              The meetings will be scheduled according to the Eastern Standard
              Time (EST) Toronto.
            </div>
            <div className="popup-footer">
              <button
                className="toggle button"
                onClick={() => {
                  setTimePopUp(false);
                }}
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CalenderIndex;
