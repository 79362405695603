import React from 'react'
import phonecall2 from "../Assets/images/call4.jpg";
import videocall from "../Assets/images/zoomcall5.jpg";
import inperson from "../Assets/images/inperson.jpg";

const Step1 = ({setService,handleTabChange}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                  <div className='d-flex flex-column align-items-center justify-content-center service-heading-container'>
                    <h1 className="service-heading"  style={{textAlign:"center"}}>
                      Book A Consultation With RCIC
                    </h1>
                    <div class="dotted-box pt-0.5" style={{textAlign:"start",marginRight:"37rem"}}>
                      <span class="dotted"></span>
                      <span class="dotted"></span>
                      <span class="dotted"></span>
                    </div>
                  </div>

                  <div className="row g-3">
                    <div className="articles">
                      <div className="col-4">
                        <div class="card swiper-slide">
                          <div class="image-content">
                            <span class="overlay"></span>
                            <div class="card-image">
                              <img
                                src={phonecall2}
                                alt=""
                                class="card-img"
                                style={{ objectPosition: "center right",objectFit:"cover" }}
                              />
                            </div>
                          </div>
                          <div class="card-content">
                            <h2 class="name">Phone | Initial Consultation </h2>
                            <p class="description">
                              Begin your journey by scheduling an Initial
                              Consultation. Connect via phone for personalized
                              advice.
                            </p>
                            <div style={{ textAlign: "start" }}>
                              <p className="subheading-time">
                                Duration : <span>30 min</span>
                              </p>
                              <p className="subheading text-start">
                                Price : <span>$80 CAD</span>
                              </p>
                            </div>
                            <button
                              class="button"
                              onClick={() => {
                                handleTabChange(1);
                                setService(0);
                              }}
                            >
                              Book Now
                              <i
                                className="fa fa-arrow-right-long"
                                style={{
                                  transform: "translateY(2px)",
                                  marginLeft: "4px",
                                }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div class="card swiper-slide">
                          <div class="image-content">
                            <span class="overlay"></span>
                            <div class="card-image">
                              <img src={inperson} alt="" class="card-img" style={{objectFit:"cover" }}/>
                            </div>
                          </div>
                          <div class="card-content">
                            <h2 class="name">In-Person Consultation</h2>
                            <p class="description">
                              Step into our office for expert guidance with
                              In-Person Consultation at Eons Immigration
                              Services.
                            </p>
                            <div style={{ textAlign: "start" }}>
                              <p className="subheading-time">
                                Duration : <span>30 min</span>
                              </p>
                              <p className="subheading text-start">
                                Price : <span>$120 CAD</span>
                              </p>
                            </div>
                            <button
                              class="button"
                              onClick={() => {
                                handleTabChange(1);
                                setService(1);
                              }}
                            >
                              Book Now
                              <i
                                className="fa fa-arrow-right-long"
                                style={{
                                  transform: "translateY(2px)",
                                  marginLeft: "4px",
                                }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div class="card swiper-slide">
                          <div class="image-content">
                            <span class="overlay"></span>
                            <div class="card-image">
                              <img
                                src={videocall}
                                alt=""
                                class="card-img"
                                style={{ objectPosition: "center center",objectFit:"cover" }}
                              />
                            </div>
                          </div>
                          <div class="card-content">
                            <h2 class="name">Online Call | Video Consultation</h2>
                            <p class="description">
                              Access professional guidance with Zoom Call |
                              Video Consultation . Expert advice, anywhere.
                            </p>
                            <div style={{ textAlign: "start" }}>
                              <p className="subheading-time">
                                Duration : <span>30 min</span>
                              </p>
                              <p className="subheading text-start">
                                Price : <span>$100 CAD</span>
                              </p>
                            </div>
                            <button
                              class="button"
                              onClick={() => {
                                handleTabChange(1);
                                setService(2);
                              }}
                            >
                              Book Now
                              <i
                                className="fa fa-arrow-right-long"
                                style={{
                                  transform: "translateY(2px)",
                                  marginLeft: "4px",
                                }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  )
}

export default Step1
