import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import axios from "axios";
import { Config } from "../Config";
import { Modal } from "reactstrap";
import Swal from "sweetalert2";

const CheckOutForm = ({ price, setTab, togglePaymentPopup }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);


  const handlePayment = async (e) => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      setLoading(true);
      try {
        const response = await axios.post(`${Config.apiUrl}/createPayment`, {
          amount: parseFloat(price).toFixed(2),
          method: paymentMethod?.id,
        });

        if (response.status == 200) {
          const formData = new FormData();
          formData.append("payment", 1);
          formData.append("transaction_id", response.data.clientSecret?.id);
          formData.append("user_id", localStorage.getItem("bookingId"));
          formData.append("consultation_id",localStorage.getItem("service"));
          try {
            await axios({
              method: "post",
              url: `${Config.apiUrl}/createTransaction`,
              data: formData,
              headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
              },}).then(()=>{
                togglePaymentPopup();
            if (localStorage.getItem("bookingId") != null) {
              setTab(4);
              localStorage.setItem("tab", 4);
            } else {
              setTab(4);
            }
            setLoading(false);
              })
          } catch (error) {
            Swal.fire({ icon: "error", text: `${error.message}` });
            // console.log(error);
            setLoading(false);
          }
        }
        setLoading(false);
      } catch (err) {
        const formData = new FormData();
          formData.append("payment", 0);
          formData.append("transaction_id", "");
          formData.append("user_id", localStorage.getItem("bookingId"));
          formData.append("consultation_id",localStorage.getItem("service"));

          await axios({
            method: "post",
            url: `${Config.apiUrl}/createTransaction`,
            data: formData,
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            },}).then(()=>{
        Swal.fire({ icon: "error", text: `${error.message}` });
       })
       setLoading(false);
      }
    } else {
      Swal.fire({ icon: "error", text: `${error.message}` });
    }
  };
  return (
    <div>
      {isLoading && <Loader />}
      {!success ? (
        <form
          onSubmit={(e) => {
            handlePayment(e);
          }}
        >
          <label>Card Number</label>

          <div className="input-payment">
            <CardElement options={{ hidePostalCode: true }} />
          </div>

          <button className="button-card w-100" type="submit">
            <span style={{ color: "rgb(255, 182, 0)", fontWeight: "650" }}>
              {" "}
              {`$${price}`}
            </span>{" "}
            - Pay with Credit / Debit Card
          </button>
        </form>
      ) : (
        <div>
          <p>Payment Done Successfully</p>
        </div>
      )}
    </div>
  );
};

export default CheckOutForm;
