import React from 'react'

const Step5 = ({scrollToRef,formdata,dateAndTime,heading,info,service,setDateAndTime,setFormdata,setTermsChecked,setTab}) => {
  const price = localStorage.getItem("finalprice")
  return (
    <div>
      <div className="d-flex flex-column align-items-center summary-container" ref={scrollToRef}>
                      <div className="animation-ctn">
                        <div className="icon icon--order-success svg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="154px"
                            height="154px"
                          >
                            <g fill="none" stroke="#22AE73" strokeWidth="2">
                              {/* Decreased the radius of the circles */}
                              <circle
                                cx="77"
                                cy="77"
                                r="50"
                                style={{
                                  strokeDasharray: "314px",
                                  strokeDashoffset: "0",
                                }}
                              ></circle>
                              <circle
                                id="colored"
                                fill="#22AE73"
                                cx="77"
                                cy="77"
                                r="50"
                                style={{
                                  strokeDasharray: "314px",
                                  strokeDashoffset: "0",
                                }}
                              ></circle>
                              {/* Adjusted the points to make the tick mark shorter */}
                              <polyline
                                className="st0"
                                stroke="#fff"
                                strokeWidth="6"
                                points="55,77 70,90 105,55"
                                style={{
                                  strokeDasharray: "78.5px",
                                  strokeDashoffset: "0",
                                }}
                              />
                            </g>
                          </svg>
                        </div>
                      </div>


                      <div
                        className="receipt-info"
                        style={{ textAlign: "center" }}
                      >
                        <h3>Your Appointment Booked successfully!</h3>
                        <p>
                          We have sent your booking information to your email
                          address.
                        </p>
                        <div className="receipt-inner-info">
                          <div className="text-start ">
                            <p className="booking-text">Booking Summary:</p>
                          </div>
                          <div className="d-flex">
                            <p class="card__recipient">Customer Name:</p>
                            <p className='ms-1'>
                              {formdata.firstname + " " + formdata.lastname}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p class="card__recipient">Service Type:</p>
                            <p className='ms-1'>{heading}</p>
                          </div>
                          <div className="d-flex">
                            <p class="card__recipient">Email:</p>
                            <p className='ms-1'>{formdata.email}</p>
                          </div>
                          <div className="d-flex">
                            <p class="card__recipient">Order Amount:</p>
                            <p className='ms-1'>{`$`+ info[service].price + ` CAD` + ` + ` + `13% HST`}</p>
                          </div>

                          <div className="d-flex">
                            <p class="card__recipient">Final Amount:</p>
                            <p className='ms-1'>{`$`+ price + ` CAD`}</p>
                          </div>
                          <div className="d-flex">
                            <p class="card__recipient">Date:</p>
                            <p className='ms-1'> {`${dateAndTime.date}, ${dateAndTime.day}`}</p>
                          </div>
                          <div className="d-flex">
                            <p class="card__recipient">Time:</p>
                            <p className='ms-1'>
                              {localStorage.getItem("bookingId") != null
                                ? dateAndTime.time
                                : dateAndTime.time}
                            </p>
                          </div>
                          <div className="d-flex justify-content-start">
                            <button
                              className="button"
                              style={{ marginLeft: "0" }}
                              onClick={() => {
                                localStorage.removeItem("bookingData");
                                localStorage.removeItem("bookingId");
                                localStorage.removeItem("tab");
                                setDateAndTime({date:"",time:"",day:""});
                                setFormdata({firstname: "",
                                lastname: "",
                                email: "",
                                mobile: "",
                                comments: "",})
                                setTermsChecked(false);


                                setTab(0);
                              }}
                            >
                              <i className="bi bi-check2-square"></i> Complete
                            </button>
                            <button
                              className="button button-pay"
                              style={{ marginLeft: "0", width:"40%" }}
                              onClick={() => {
                                localStorage.removeItem("bookingData");
                                localStorage.removeItem("bookingId");
                                localStorage.removeItem("tab");
                                setDateAndTime({date:"",time:"",day:""});
                                setFormdata({firstname: "",
                                lastname: "",
                                email: "",
                                mobile: "",
                                comments: "",})
                                setTermsChecked(false);


                                setTab(0);
                              }}
                            >
                              <i className="bi bi-check2-square"></i> Book Another Meeting
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
    </div>
  )
}

export default Step5
