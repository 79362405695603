
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/Assets/css/spacing.css";
import "../src/Assets/css/home.css";
import Services from "./pages/Services";
import { useEffect } from "react";
import Aos from "aos";
import Terms from "./pages/Terms";
import { Config } from "./Config";

function App() {
  useEffect(() => {
    Aos.init({
        offset: 100, // offset (in px) from the original trigger point
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: "ease-out",
        once: false,
    });
}, []);
  return (
    <div className="App1">
      <Router>
        <Routes>
          <Route path={`${Config.appUrl}`} element={<Services/>} />
          <Route path={`${Config.appUrl}/terms`} element={<Terms/>} />
         
        </Routes>
      </Router>
    </div>
  );
}

export default App;
