import React from "react";
import Navbar from "./Home/Navbar";
import Banner from "./Home/Banner";
import Footer from "./Footer";

const Terms = () => {
  return (
    <div className="">
      <Navbar />

      <Banner />
      <div className="bd-color-grey">
        <div className="container-3 pt-0">
          <div className="pt-40 pb-50">
            <div className="bd-multi-form-card privacy-container">
              <h1>Initial Consultation Agreement:</h1>
              <p>
                Once booked, please fill the Initial assessment form here:
                <a
                  href="https://www.eonsimmigration.ca/"
                  target="blank"
                  style={{ color: "#212161", fontWeight: "600" }}
                >
                  {" "}
                  https://www.eonsimmigration.ca
                </a>{" "}
                <br />
                We'll be discussing your queries regarding Canadian Immigration
                in detail over an Initial Consultation. The meeting will be
                conducted over a voice call only. You may keep the camera turned
                off during the meeting. Time duration of this call is as per the
                booked time slot.
              </p>
              <div>
                <h6>What can you expect to happen in the meeting?</h6>
                <ul className="sidebox-info">
                  <li>
                    <p className="sidebox-infotext">
                      ​Initial Consultation regarding your profile for suitable
                      Canadian Immigration Options or requirements
                    </p>
                  </li>
                  <li className="sidebox-infotext">
                    Overall discussion on steps to apply, expected time-frame
                    and applicable fees etc.
                  </li>
                  <li>
                    Question and Answer session on general enquiries around
                    Canadian immigration
                  </li>
                  <li>
                    Temporary or Permanent program options(If any) shall be
                    suggested based on your profile and eligibility.
                  </li>
                  <li>
                    ​Initial Consultation regarding your profile for suitable
                    Canadian Immigration Options or requirements
                  </li>
                </ul>
                <p>
                  Please note, we are NOT a placement agency and we DO NOT
                  provide jobs. Please do not schedule a call if your sole
                  purpose is to talk about jobs in Canada. We can help you
                  identify Canada PR options (if any) based on your profile. Any
                  such bookings shall be subject to instant cancellation and
                  non-refundable.
                </p>
                <p>
                  We do not share any document checklists over an Initial
                  Consultation. Documents Checklists shall be issued to
                  onboarded clients only.
                </p>
                <p>
                  If you choose to proceed with the booking, it is deemed that
                  you agree to the Initial
                  Consultation Agreement as mentioned below.
                </p>
                <h5>INITIAL CONSULTATION AGREEMENT</h5>
                <p>
                  This Initial Consultation Agreement is made this day (booking
                  through the tool below) between Eons Immigration, and Client
                  (Person(s) booking though this online booking tool and
                  Person(s) going to attend the scheduled Initial Consultation
                  meeting)
                </p>
                <p>
                  This Agreement shall be governed by the laws in effect in the
                  Province/Territory of Ontario, Canada, and the federal laws of
                  Canada applicable therein.
                </p>
                <h4>1. Scope of Service</h4>
                <p>
                  The RCIC will provide the Client with Initial Consultation
                  under the terms of this Agreement
                </p>
                <ul className="modal-ul">
                  <li className="modal-li">
                    <p className="privacy-text">
                      Review information provided by the Client and provide
                      consultation on the current status of Canadian immigration
                      laws and policies where it relates to Client’s inquiry;
                    </p>
                  </li>
                  <li className="modal-li">
                    <p className="privacy-text">
                      The work done under this agreement will be limited to the
                      time as mentioned in the selected option used while
                      booking the Initial Consultation;
                    </p>
                  </li>
                  <li className="modal-li">
                    <p className="privacy-text">
                      The information provided to the consultant in the Initial
                      Consultation Form and during the consultation is for the
                      purpose of obtaining a consultation only and is not
                      intended for the purpose of retaining the RCIC for
                      application, case, submission or representation in any
                      matter, until a written legal services retainer agreement
                      establishing such nature of the relationship has been
                      executed between the parties.
                    </p>
                  </li>
                </ul>
                <h4>2. Limits of Engagement</h4>
                <p>
                  The services to be provided under this Agreement are limited
                  to a single Initial consultation only and on the relevant
                  matters described above. This agreement does not obligate the
                  RCIC to not provide further information or advice to the
                  Client or to act for the Client in any application,
                  representation, submissions, or proceedings. Should the Client
                  require further services, advice, or representation from the
                  RCIC following this initial consultation, the Client must sign
                  a separate retainer agreement with the RCIC.
                </p>
                <h4>3. Client Responsibility</h4>
                <p>
                  The Client must provide the RCIC with such factual information
                  and documentation as are required to perform the consultation.
                </p>
                <p>
                  The Client must be accurate and honest and must inform the
                  RCIC of all information, even if negative or adverse, which
                  might be relevant to the advice provided by the RCIC in this
                  matter. Failure to fully disclose all relevant information to
                  the RCIC shall render the consultation provided null and void
                  and shall void this Agreement, or seriously affect the outcome
                  of the application of the Client or the retention of any
                  status that the Client may obtain. In such an event, the RCIC
                  shall not be held responsible or liable for any of the
                  Client’s matters. The fee paid by the Client shall be
                  non-refundable.
                </p>
                <p>
                  The Client fully acknowledges that all information given
                  during this consultation is provided as per the Canadian
                  Immigration laws and policies valid until the time of
                  consultation delivery. All information given during this
                  consultation shall be considered null and void if there is a
                  change in policy or immigration law at a later date after the
                  advice is provided to the Client. The fee paid by the Client
                  shall be non-refundable.
                </p>
                <h4>4. Termination</h4>
                <p>
                  a)This Agreement is considered terminated upon completion of
                  tasks identified under Clause 3 of this agreement. It is the
                  Clients’ responsibility to settle any outstanding fees or dues
                  for work performed by RCIC prior to termination.
                </p>
                <p>
                  b) Upon official termination, if the CLIENT(s) decides to
                  proceed with any future applications that are personally done
                  by the Client(s) as self-application and/or their
                  representatives, lawyers or consultants will be done without
                  the knowledge, approval, or involvement of RCIC and should
                  these cause any impact, refusal, rejection, negative results
                  or future inadmissibility, Eons immigrations
                  and appointed RCIC shall not be held liable or be held legally
                  responsible for any consequences.
                </p>
                <h4> 5.Payment terms and Refund Policy</h4>
                <p>
                  a)Fees shall be deemed earned by the RCIC immediately upon the
                  commencement of the provision of professional advice and
                  consultation.
                </p>
                <p>
                  b)Fees paid by the Client under this agreement are
                  non-refundable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
